import React from 'react';
import { useTranslation } from 'react-i18next';

import { PenToSquareIcon } from 'shared/components/icons/PenToSquareIcon';
import { AddressType } from 'api/types';
import Button from 'shared/components/Button/Button';
import { Card, EditIconWrapper, Header, Label, StaticTextWrapper, Value } from './UserCard.styles';

export type UserCardAddressType = Omit<AddressType, 'id' | 'archived' | 'confirmed'>;

export type UserCardUserType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address?: UserCardAddressType;
};

type Props = {
  header?: string;
  user: UserCardUserType;
  onEditUser: () => void;
  styleVariant?: 'primary' | 'secondary';
};

export function UserCard({ user, header, styleVariant = 'primary', onEditUser }: Props) {
  const { t } = useTranslation();
  const { address } = user;

  const list = [
    { label: t('account.name'), value: `${user.firstName} ${user.lastName}` },
    { label: t('account.email'), value: user.email },
    { label: t('account.phoneNumber'), value: user.phoneNumber },
    {
      label: t('account.address'),
      value: `${address?.street_1}${address?.street_2 ? ` ${address.street_2},` : ','} ${address?.city} ${address?.zip_code}`,
    },
  ];

  return (
    <Card>
      {header && <Header as="h3">{header}</Header>}

      <StaticTextWrapper>
        <EditIconWrapper>
          <Button styleVariant="tertiary" Icon={PenToSquareIcon} onClick={onEditUser} />
        </EditIconWrapper>

        {list.map((item) => (
          <div key={`${user.firstName}-${item.value}`}>
            <Label as="p" $styleVariant={styleVariant}>
              {item.label}
            </Label>
            <Value as="p" $styleVariant={styleVariant}>
              {item.value}
            </Value>
          </div>
        ))}
      </StaticTextWrapper>
    </Card>
  );
}

export default UserCard;
