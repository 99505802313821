import React from 'react';
import { useTranslation } from 'react-i18next';
import SystemCardIndividualOutput from './SystemCardIndividualOutput';
import { SystemCardOutputsWrapper } from './SystemCardOutputsSection.styles';

type SystemCardOutputsProps = {
  homeEnergy: string;
  batteryEnergy: string;
  gridEnergy: string;
  hasBatteryMonitoring: boolean;
};

export default function SystemCardOutputsSection({
  homeEnergy,
  batteryEnergy,
  gridEnergy,
  hasBatteryMonitoring,
}: SystemCardOutputsProps) {
  const { t } = useTranslation();

  const showBatteryOutput = hasBatteryMonitoring;

  return (
    <SystemCardOutputsWrapper $hasBattery={showBatteryOutput}>
      <SystemCardIndividualOutput
        header={t('systemCard.homeHeader')}
        value={homeEnergy || ''}
        marginBottom={showBatteryOutput}
      />
      {showBatteryOutput && (
        <SystemCardIndividualOutput
          header={t('systemCard.batteryHeader')}
          value={batteryEnergy || ''}
          borderLeft
          marginBottom
        />
      )}
      <SystemCardIndividualOutput
        header={t('systemCard.gridHeader')}
        value={gridEnergy || ''}
        mobileBorderTop={showBatteryOutput}
        borderLeft
      />
    </SystemCardOutputsWrapper>
  );
}
