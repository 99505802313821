import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountType, AddressType } from 'api/types';
import { Header } from 'shared/components/Header/Header';
import { OverHeaderWrapper } from './OverviewHeader.styles';
import { AddressSelect } from '../AddressSelect/AddressSelect';

type Props = {
  selectedAccount: AccountType;
  accounts: AccountType[];
  userAddress: AddressType;
  onAccountChange: (accountId: string) => void;
};

export function OverviewHeader({ selectedAccount, userAddress, accounts, onAccountChange }: Props) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const optionsArray: { id: string; address: AddressType }[] = [];

    accounts.forEach((account) => {
      account.agreements.forEach((agreement) => {
        optionsArray.push({ id: agreement.account, address: agreement.system.address });
      });
    });

    return optionsArray;
  }, [accounts]);

  return (
    <OverHeaderWrapper>
      <Header pageName="OVERVIEW" title={t('overview.welcomeBack')} />

      <AddressSelect
        selectedOption={{ id: selectedAccount.id, address: userAddress }}
        options={options}
        onSelectOption={onAccountChange}
      />
    </OverHeaderWrapper>
  );
}

export default OverviewHeader;
