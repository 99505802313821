import styled from 'styled-components';

export const HouseGraphicImage = styled.img`
  height: 220px;
  width: 288px;
  object-fit: cover;
  object-position: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 308px;
    width: 393px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 308px;
    width: 471px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 352px;
    width: 637px;
  }
`;

export default HouseGraphicImage;
