import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import { FeatureFlagProvider } from 'contexts/FeatureFlagContext';
import { UserProvider } from 'contexts/UserContext';
import SurflyRender from 'config/initializers/surfly/surfly';
import { App } from './App';
import { StateContextProvider } from './contexts/StateContext';
import initializeDatadog from './config/initializers/datadog';
import initializeFullstory from './config/initializers/fullstory';
import initI18n from './config/initializers/i18n';
import './config/initializers/inContact';
import './static/fonts/avenir/MyFontsWebfontsKit.css';

initializeDatadog();
initializeFullstory();
initI18n();

const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FeatureFlagProvider>
        <StateContextProvider>
          <UserProvider>
            <>
              <SurflyRender />
              <App />
            </>
          </UserProvider>
        </StateContextProvider>
      </FeatureFlagProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
