import React from 'react';

import { CardDescription, IconPosition } from 'shared/components/Card/Card.styles';
import { Card } from 'shared/components/Card/Card';
import ManageCardWrapper from './ManageCard.styles';

type Props = {
  title: string;
  iconElement: JSX.Element;
  subHeader: string;
  route?: string;
  url?: string;
  onClick?: () => void;
};

export function ManageCard({ title, iconElement, subHeader, route, url, onClick }: Props) {
  return (
    <ManageCardWrapper>
      <Card
        title={title}
        route={route}
        url={url}
        onClick={onClick}
        icon={{
          element: iconElement,
          color: 'grey',
        }}
        iconPosition={IconPosition.NEXT}
        includeUrlArrow={false}
        width="100%"
        height="100%"
      >
        <CardDescription as="p">{subHeader}</CardDescription>
      </Card>
    </ManageCardWrapper>
  );
}

export default ManageCard;
