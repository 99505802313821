import { styled, DefaultTheme } from 'styled-components';
import { defaultStyles, Styles } from 'react-modal';

import {
  TDropdownStyleVariantsKey,
  TDropdownStatesKey,
} from 'shared/design-system/theme/dropdowns';
import { DropdownContainer } from '../Dropdown/Dropdown.styles';

export const modalStyles = (theme: DefaultTheme): Styles => ({
  overlay: {
    ...defaultStyles.overlay,
    backgroundColor: `${theme.colors.backgroundAccentGreyStrongerGrey80}bf`,
    zIndex: 500,
  },
  content: {
    ...defaultStyles.content,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    inset: 0,
    border: 'none',
    background: 'none',
    overflow: 'hidden',
    padding: 0,
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
});

export const CustomSelectContainer = styled.div`
  cursor: pointer;
  position: relative;
  align-self: flex-end;

  ${DropdownContainer} {
    width: fit-content;
    right: 0;
    border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
    margin-top: 9px;
    h5 {
      text-wrap: nowrap;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      position: fixed;
      width: 100%;
      border: 0;
      border-top: 1px solid ${({ theme }) => theme.colors.backgroundInteractionHoverGrey10};
      border-radius: 0;
      margin-top: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    align-self: center;
  }
`;

export const NativeSelect = styled.select`
  display: none;
`;

type SelectSelectedProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
};

export const SelectSelected = styled.div<SelectSelectedProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  &:focus {
    outline: none;
    z-index: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 0;
  }
`;

export const DropdownWrapper = styled.div`
  overflow: hidden;
`;

export const Scrim = styled.div<{ $show: boolean }>`
  position: fixed;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgroundOpacityOpacityScrimGrey8075};
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  z-index: 10;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  span {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  height: 87.6%;
  width: 100%;
  padding: 16px 0;
  background-color: ${({ theme }) => theme.colors.cardElevationMidWhite};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 100%;
    width: 328px;
    align-self: end;
    border-radius: 20px 0 0 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 100%;
    width: 391px;
    align-self: end;
    border-radius: 20px 0 0 20px;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
`;
