import styled from 'styled-components';

import { CardMainBody } from 'shared/components/Card/Card.styles';
import {
  SystemCardTotalOutputValueWrapper,
  SystemCardTotalOutputValue,
  SystemCardTotalOutputValueSuffix,
} from './SystemCardOutputsSection.styles';

const SystemCardWrapper = styled.div`
  ${({ theme }) => `
      
      align-items: flex-start;

      // Small Styling
      margin: 24px 0 15px 0;
      ${CardMainBody} {
        border-radius: 24px;
        background: ${theme.colors.cardElevationHighWhite};
        min-width: 288px;
        width: 288px;
        padding: 16px 12px 12px 12px;
      }


      @media (${theme.breakpoints.small} <= width < ${theme.breakpoints.medium}) {
        margin: 0 0 0 23px;
        ${CardMainBody} {
          padding: 16px 12px 12px 12px;
          min-width: 308px;
          width: 308px;
        }

      }
      @media (${theme.breakpoints.medium} <= width < ${theme.breakpoints.large}) {
        margin: 0 0 0 32px;
        ${CardMainBody} {
          padding: 20px 16px 16px 16px;
          min-width: 328px;
          width: 328px;
        }
        ${SystemCardTotalOutputValueWrapper} {
          padding: 20px 0 40px 44px;
        }
        ${SystemCardTotalOutputValue} {
          font-size: 34px;
          line-height: 44px;
          font-weight: 800;
        }
        ${SystemCardTotalOutputValueSuffix} {
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;

        }
      }
      @media (width >= ${theme.breakpoints.large}) {
        margin: 0 0 0 48px;
        ${CardMainBody} {
          padding: 24px 16px 16px 16px;
          min-width: 440px;     
          width: 440px;      
        }
        ${SystemCardTotalOutputValueWrapper} {
          padding: 24px 0 44px 44px;
        }
        ${SystemCardTotalOutputValue} {
          font-size: 34px;
          line-height: 44px;
          font-weight: 800;
        }
        ${SystemCardTotalOutputValueSuffix} {
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
        }
      }
    `}
`;

export default SystemCardWrapper;
