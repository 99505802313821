import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useNavigate } from 'react-router';
import pages from 'pages';

import { Text } from 'shared/components/Text/Text';
import SupportIcon from 'shared/components/icons/SupportIcon';
import BillingIcon from 'shared/components/icons/BillingIcon';
import AgreementsIcon from 'shared/components/icons/AgreementsIcon';
import URLS from 'constants/urls';
import { AgreementType } from 'api/types';
import { ManageCard } from '../ManageCard/ManageCard';
import {
  ManageSectionWrapper,
  ManageSectionContents,
  SubText,
  TitleWrapper,
} from './ManageSection.styles';

type Props = {
  agreement: AgreementType | null;
};

export function ManageSection({ agreement }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const agreementOnClick = (agreement_id: string) => {
    navigate(resolvePath(`${pages.AGREEMENTS}/${agreement_id}`, `/${pages.DASHBOARD}`));
  };

  return (
    <ManageSectionWrapper>
      <ManageSectionContents>
        <TitleWrapper>
          <Text as="h2">{t('overview.manage')}</Text>
          <SubText as="p">{t('overview.manageSubHeader')}</SubText>
        </TitleWrapper>
        <ManageCard
          title={t('overview.billing')}
          subHeader={t('overview.billingSubHeader')}
          url={URLS.EVERBRIGHT_ONLINE_ACCOUNT}
          iconElement={<BillingIcon />}
        />
        {agreement && (
          <ManageCard
            title={t('overview.agreement')}
            subHeader={t('overview.agreementSubHeader')}
            onClick={() => agreementOnClick(agreement.id)}
            iconElement={<AgreementsIcon filled={false} />}
          />
        )}

        <ManageCard
          title={t('overview.support')}
          subHeader={t('overview.supportSubHeader')}
          route={resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`).pathname}
          iconElement={<SupportIcon />}
        />
      </ManageSectionContents>
    </ManageSectionWrapper>
  );
}

export default ManageSection;
