import styled from 'styled-components';

const ManageCardWrapper = styled.div`
  height: 118px;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 142px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 132px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 145px;
  }
`;

export default ManageCardWrapper;
