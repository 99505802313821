import styled from 'styled-components';

export const SystemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  @media (width >= ${({ theme }) => theme.breakpoints.small}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cardElevationLowWhite};
  align-items: center;
  @media (width >= ${({ theme }) => theme.breakpoints.small}) {
    align-items: flex-start;
  }
`;

export default Container;
