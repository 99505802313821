import React, { useMemo, useState } from 'react';
import useUser from 'hooks/useUser';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import DataDogLogs from 'shared/logging/DataDogLogs';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { getWelcomeChecklist } from 'api/agreement';
import { MilestoneType, WelcomeChecklistResult, WelcomeChecklistStatusType } from 'modules/types';
import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { AuthCopyAckModal } from 'shared/components/AuthCopyAckModal/AuthCopyAckModal';
import { SystemDetails } from 'modules/earlyAccess/SystemDetails/SystemDetails';
import { SupportForm } from 'modules/support/components/SupportForm/SupportForm';
import { BillingAddressType, FlagName } from 'api/types';
import { Banner } from 'shared/components/Banner/Banner';
import { useTheme } from 'styled-components';
import { toast } from 'shared/components/Toast/Toast';
import { updateUserBillingAddress, updateUserBillingAddressConfirmation } from 'api/user';
import { getMonitoringSystemInfo } from 'modules/system/utils/getMonitoringSystemInfo';
import { Body, ProjectContainer, StatusContainer, SupportFormWrapper } from './Status.styles';
import { ProjectStatus } from '../components/ProjectStatus/ProjectStatus';
import { StatusAccordion } from '../components/StatusAccordion/StatusAccordion';
import { shouldLoadWelcomeChecklist } from '../utils/shouldLoadWelcomeChecklist';
import { WelcomeChecklist } from './WelcomeChecklist/WelcomeChecklist';
import { AutopayModal } from '../components/AutoPayModal/AutopayModal';
import { StatusHeader } from '../components/StatusHeader/StatusHeader';
import {
  perSessionAchDiscountModalM0,
  perSessionAchDiscountModalM2,
  perSessionAuthCopyAckModal,
  perSessionAutopayBannerKey,
  perSessionModalKey,
  perSessionAchCompletionTimeKey,
} from '../../../constants/localStorageKeys';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { BillingAddressModal } from '../components/BillingAddressModal/BillingAddressModal';
import { BillingAddressFormModal } from '../components/BillingAddressFormModal/BillingAddressFormModal';

enum ModalType {
  CHECKLIST,
  AUTOPAY,
  BILLING_ADDRESS,
  BILLING_FORM,
}

// Since we don't know how long it takes Docusign to send webhook to us to update agreement ach_elected,
// we have 1 hour window threshold in local storage in case ach_elected still return false
const ACH_ELECTED_EXPIRED_WINDOW_CHECK = 60 * 60 * 1000;

export function Status() {
  const [achFormCompletionTime, setAchFormCompletionTime] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const [addressDisplay, setAddressDisplay] = useState<BillingAddressType | undefined>(undefined);
  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const handleOpenModal = (modal: ModalType) => {
    setOpenModal(modal);
  };

  const handleOpenBillingForm = () => {
    setAddressDisplay(undefined);
    handleOpenModal(ModalType.BILLING_FORM);
  };

  const handleCloseBillingAddressModal = () => {
    handleCloseModal();
    setAddressDisplay(undefined);
  };

  const handleBackBillingForm = async () => {
    handleOpenModal(ModalType.BILLING_ADDRESS);
  };
  const { userResult, selectedAccount, setSelectedAccount, refetch: refetchUser } = useUser();
  const user = userResult?.user;
  const { system } = useMemo(() => getMonitoringSystemInfo(selectedAccount), [selectedAccount]);

  const displayChangeBillingAddress = user?.address !== system?.address && !user?.address.confirmed;

  const changeBillingAddress = user?.address;

  const handleSubmitBillingForm = (formdata: BillingAddressType) => {
    handleOpenModal(ModalType.BILLING_ADDRESS);
    setAddressDisplay(formdata);
  };

  const { mutate } = useMutation({
    mutationFn: updateUserBillingAddress,
    onSuccess: async () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('confirmBillingAddress.success'),
        theme,
      });
      refetchUser();
      handleCloseModal();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('confirmBillingAddress.error'),
        theme,
      });
      setAddressDisplay(undefined);
    },
  });
  const mutationConfirmation = useMutation({
    mutationFn: updateUserBillingAddressConfirmation,
    onSuccess: async () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('confirmBillingAddress.success'),
        theme,
      });
      refetchUser();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('confirmBillingAddress.error'),
        theme,
      });
      setAddressDisplay(undefined);
    },
  });

  const handleConfirmBillingAddress = () => {
    handleCloseModal();
    const street1 = addressDisplay?.street_1;
    const street2 = addressDisplay?.street_2;
    const city = addressDisplay?.city;
    const state = addressDisplay?.state;
    const zipCode = addressDisplay?.zip_code;
    const confirmed = true;
    if (changeBillingAddress) {
      if (addressDisplay !== undefined) {
        mutate({
          user_id: user?.id,
          body: {
            address: {
              street_1: street1 || '',
              street_2: street2 || '',
              city: city || '',
              state: state || '',
              zip_code: zipCode || '',
              confirmed,
            },
          },
        });
      } else {
        mutationConfirmation.mutate({
          user_id: user?.id,
          confirmed: { address: { confirmed } },
        });
      }
    }
  };

  const agreement =
    selectedAccount?.agreements.find(
      (_agreement) =>
        _agreement.welcome_checklists?.length === 0 &&
        _agreement.current_milestone === MilestoneType.CONTRACT_SIGNED,
    ) || selectedAccount?.agreements[0];

  const welcomeChecklists = agreement ? agreement.welcome_checklists : [];

  const hasCompletedWelcomeChecklist =
    welcomeChecklists.length > 0 &&
    welcomeChecklists[welcomeChecklists.length - 1].result === WelcomeChecklistResult.PASS;

  const { data: welcomeChecklistQuestions, isFetching: isFetchingWelcomeChecklist } = useQuery(
    ['welcomeChecklist', agreement, hasCompletedWelcomeChecklist],
    () => getWelcomeChecklist(agreement?.id!).then((res) => res.data),
    {
      enabled: agreement && shouldLoadWelcomeChecklist(agreement),
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        DataDogLogs.error(
          'Status',
          {
            ...(userResult?.user ? { user: userResult?.user } : {}),
            agreement,
            selectedAccount,
            additionalInfo: {
              hasCompletedWelcomeChecklist,
              shouldLoadWelcomeChecklist: agreement
                ? shouldLoadWelcomeChecklist(agreement)
                : 'agreement is undefined',
            },
          },
          error,
        );
      },
    },
  );

  const isLoading = !userResult || userResult.loading;

  const activeAccounts =
    userResult?.user?.accounts.filter((userAccount) => userAccount.agreements.length) || [];

  const isEarlyAccess = Boolean(activeAccounts?.length);

  const currentMilestone = agreement?.current_milestone || MilestoneType.IN_SERVICE;

  const showDWC = !hasCompletedWelcomeChecklist && agreement && welcomeChecklistQuestions;

  let modalKey: any;
  if (currentMilestone === MilestoneType.CONTRACT_SIGNED) modalKey = perSessionAchDiscountModalM0;
  if (currentMilestone === MilestoneType.INSTALLATION_COMPLETED)
    modalKey = perSessionAchDiscountModalM2;

  const {
    flags: { [FlagName.achModal]: achModalEnabled },
  } = useFeatureFlags();

  // should display the autopay modal for m0 and m2 agreements only
  const handleShowWelcomeModal = () => {
    if (
      modalKey &&
      (modalKey === perSessionAchDiscountModalM2 ||
        (agreement?.ach_discount_eligible && agreement.ach_elected))
    )
      handleOpenModal(ModalType.AUTOPAY);
  };

  const handleCloseWelcomeChecklist = async (welcomeChecklistResult?: WelcomeChecklistResult) => {
    await refetchUser();
    handleOpenModal(ModalType.CHECKLIST);

    if (welcomeChecklistResult === WelcomeChecklistResult.PASS) {
      handleShowWelcomeModal();
    }
  };

  const handleAccountChange = (accountId: string) => {
    if (accountId !== selectedAccount?.id) {
      const newAccount = user?.accounts.find((account) => account.id === accountId);
      setSelectedAccount(newAccount);
    }
  };

  const handleCompleteAutopayModal = () => {
    if (!agreement?.id) return;

    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);
    if (perSessionModalsRaw !== null && modalKey) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      modalsObj[modalKey] = true;
      localStorage.setItem(perSessionModalKey, JSON.stringify(modalsObj));
    } else if (modalKey) {
      localStorage.setItem(perSessionModalKey, JSON.stringify({ [modalKey]: true }));
    }

    const perSessionAchCompletionTimeRaw = localStorage.getItem(perSessionAchCompletionTimeKey);
    if (perSessionAchCompletionTimeRaw !== null) {
      const completionTimeObj = JSON.parse(perSessionAchCompletionTimeRaw);
      completionTimeObj[agreement.id] = Date.now();
      localStorage.setItem(perSessionAchCompletionTimeKey, JSON.stringify(completionTimeObj));
    } else {
      localStorage.setItem(
        perSessionAchCompletionTimeKey,
        JSON.stringify({ [agreement.id]: Date.now() }),
      );
    }

    setAchFormCompletionTime(Date.now());
  };

  const banner = useMemo(() => {
    if (!achModalEnabled) {
      return undefined;
    }

    if (!agreement || agreement?.ach_elected) {
      return undefined;
    }

    if (!isEarlyAccess) {
      return undefined;
    }

    if (achFormCompletionTime !== null) {
      if (achFormCompletionTime + ACH_ELECTED_EXPIRED_WINDOW_CHECK > Date.now()) {
        return undefined;
      }
    } else {
      const perSessionAchCompletionTimeRaw = localStorage.getItem(perSessionAchCompletionTimeKey);
      if (perSessionAchCompletionTimeRaw !== null) {
        const achCompletionTimeObj = JSON.parse(perSessionAchCompletionTimeRaw);
        if (achCompletionTimeObj && achCompletionTimeObj[agreement.id]) {
          if (achCompletionTimeObj[agreement.id] + ACH_ELECTED_EXPIRED_WINDOW_CHECK > Date.now()) {
            return undefined;
          }
        }
      }
    }

    return (
      <Banner
        bannerKey={perSessionAutopayBannerKey(agreement?.id || '')}
        bannerText={
          agreement?.ach_discount_eligible
            ? t('autopayBanner.setupAndSave')
            : t('autopayBanner.goodbyeToChecks')
        }
        link={{
          text: t('autopayBanner.clickHere'),
          onClick: () => {
            handleOpenModal(ModalType.AUTOPAY);
          },
        }}
      />
    );
  }, [achModalEnabled, agreement, isEarlyAccess, achFormCompletionTime, t]);

  return (
    <>
      <StatusContainer>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {user && (
              <StatusHeader
                selectedAccount={selectedAccount}
                accounts={user.accounts}
                userAddress={user.address}
                banner={banner}
                onAccountChange={handleAccountChange}
              />
            )}

            <Body>
              <ProjectContainer>
                <ProjectStatus
                  currentMilestone={isEarlyAccess ? currentMilestone : 'CANCELED'}
                  actionRequiredFlag={displayChangeBillingAddress}
                  welcomeChecklistStatus={
                    agreement?.welcome_checklist_status || WelcomeChecklistStatusType.NOT_REQUIRED
                  }
                />

                {isEarlyAccess ? (
                  <StatusAccordion
                    currentMilestone={currentMilestone}
                    agreement={agreement}
                    isFetchingWelcomeChecklist={isFetchingWelcomeChecklist}
                    onOpenWelcomeChecklist={() => {
                      handleOpenModal(ModalType.CHECKLIST);
                    }}
                    displayChangeBillingAddress={displayChangeBillingAddress}
                    onConfirmBillingAddress={() => {
                      handleOpenModal(ModalType.BILLING_ADDRESS);
                    }}
                  />
                ) : (
                  <SupportFormWrapper>
                    <SupportForm email={userResult.user?.email || ''} />
                  </SupportFormWrapper>
                )}
              </ProjectContainer>

              {agreement && isEarlyAccess && <SystemDetails agreement={agreement} />}
            </Body>
          </>
        )}
        {showDWC && (
          <ErrorBoundary>
            <WelcomeChecklist
              agreement={agreement}
              checklistQuestions={welcomeChecklistQuestions}
              isOpen={openModal === ModalType.CHECKLIST}
              onCloseWelcomeChecklist={handleCloseWelcomeChecklist}
            />
          </ErrorBoundary>
        )}
        {achModalEnabled && agreement && banner ? (
          <AutopayModal
            isOpen={openModal === ModalType.AUTOPAY}
            modalKey={modalKey}
            discountEligible={!!agreement?.ach_discount_eligible}
            agreement={agreement}
            onCompleteFormModal={handleCompleteAutopayModal}
            onCloseAutopayModal={handleCloseModal}
          />
        ) : null}
      </StatusContainer>
      <AuthCopyAckModal account={selectedAccount} modalKey={perSessionAuthCopyAckModal} />
      <BillingAddressModal
        isOpen={openModal === ModalType.BILLING_ADDRESS}
        addressDisplay={addressDisplay}
        onChangeConfirmed={handleConfirmBillingAddress}
        onOpenBillingForm={handleOpenBillingForm}
        onCloseBillingAddressModal={handleCloseBillingAddressModal}
        addressToChange={changeBillingAddress}
      />
      <BillingAddressFormModal
        frontDropDown
        openBillingFormModal={openModal === ModalType.BILLING_FORM}
        handleCloseBillingForm={handleCloseModal}
        handleBackBillingForm={handleBackBillingForm}
        handleSubmitBillingForm={handleSubmitBillingForm}
      />
    </>
  );
}

export default Status;
