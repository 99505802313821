import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'shared/components/Text/Text';
import { ChatWrapper } from './Chat.styles';

export function Chat() {
  const { t } = useTranslation();

  return (
    <ChatWrapper>
      <Text as="h1">{t('chat.liveChat')}</Text>
      <Text as="p" $margin="36px 0">
        {t('chat.chatWithAnAgent')}
      </Text>
    </ChatWrapper>
  );
}
export default Chat;
