import styled, { DefaultTheme } from 'styled-components';
import { breakpoints } from 'shared/design-system/theme/breakpoints';
import { FONT_FAMILY, Text } from '../Text/Text';

export type IconColor = 'green' | 'blue' | 'teal' | 'magenta' | 'grey';
export enum IconPosition {
  ABOVE = 'above',
  NEXT = 'next',
}
const iconFillColors = (theme: DefaultTheme) => ({
  default: {
    green: theme.colors.chartChartGreenFunction540,
    blue: theme.colors.chartChartBlueSecondary2100,
    teal: theme.colors.chartChartTealAccent2100,
    magenta: theme.colors.chartChartMagentaAccent1100,
    grey: theme.colors.iconIconPrimarySecondary1100,
  },
  hover: {
    green: theme.colors.chartChartGreenHoverFunction560,
    blue: theme.colors.chartChartBlueHoverFunction4100,
    teal: theme.colors.chartChartTealHoverAccent2120,
    magenta: theme.colors.chartChartMagentaHoverAccent1120,
    grey: theme.colors.iconIconPrimarySecondary1100,
  },
  active: {
    green: theme.colors.chartChartBrandGreenPressedFunction560,
    blue: theme.colors.chartChartBluePressedFunction41002,
    teal: theme.colors.chartChartTealPressedAccent2160,
    magenta: theme.colors.chartChartMagentaPressedAccent1160,
    grey: theme.colors.iconIconPrimarySecondary1100,
  },
  background: {
    green: theme.colors.chartChartBrandGreenPressedFunction560,
    blue: theme.colors.chartChartBluePressedFunction41002,
    teal: theme.colors.chartChartTealPressedAccent2160,
    magenta: theme.colors.chartChartMagentaPressedAccent1160,
    grey: theme.colors.backgroundSecondaryGrey05,
  },
});

export const CardIconContainer = styled.div`
  ${({ theme }) => `
        border-radius: 50%;
        box-shadow: ${theme.elevations.extraSunken};
        display: flex;
        svg {
            margin: auto;
        }
    `}
`;

export const CardTitle = styled(Text)`
  ${({ theme }) => `
        display: flex;
        color: ${theme.colors.textPrimarySecondary1100};
        align-items: center;
        line-height: 1.5;
        svg {
            margin-left: 12px;
            path {
                fill: ${theme.colors.iconIconPrimarySecondary1100};
            }
        }
    `}
`;

export const CardDescription = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textSecondarySubtleGrey80};
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 100;
        background: unset;
    `}
`;

export const CardDescriptionBold = styled(CardDescription)`
  font-weight: 700;
`;

export const CardActionText = styled.p`
  ${({ theme }) => `
            display: flex;
            font-family: ${FONT_FAMILY};
            font-size: 16px;
            line-height: 1.5;
            font-weight: 600;
            margin: 0;
            margin-top: 12px;
            color: ${theme.colors.buttonButtonPrimarySecondary2100};
            background: transparent;
            svg {
                width: 16px;
                height: 16px;
                margin: 3px 8px 0px 0px;
                path {
                    fill: ${theme.colors.buttonButtonPrimarySecondary2100};
                }
            }
        `}
`;

export const CardMainBody = styled.div<{
  $cardHeight?: string;
  $cardWidth?: string;
  $iconColor?: IconColor;
  $iconPosition?: IconPosition;
}>`
  ${({ theme, $cardHeight, $cardWidth, $iconColor, $iconPosition, role }) => {
    const iconColors = iconFillColors(theme);

    return `
    cursor: ${role === 'button' ? 'pointer' : 'default'};
    max-width: ${$cardWidth ? '' : '300px'};
    background: ${theme.colors.cardElevationMidWhite};
    @media (width < ${breakpoints.small}) {
        padding: 16px; 
        width: ${$cardWidth ? `${$cardWidth}` : ''};
        ${CardIconContainer} {
            height: 36px;
            width: 36px;
            svg {
                height: 16px;
                width: 16px;
            }
        }
        ${CardTitle} {
            font-size: 16px;
            font-weight: 800;
            line-height: 24px; /* 150% */
            margin: ${$iconColor && $iconPosition !== IconPosition.NEXT ? 16 : 0}px 0px 8px 0px;
            svg {
                margin-top: -2px;
                margin-left: 8px;
                height: 16px;
                width: 16px;
            }
            ${CardIconContainer} {
                height: 30px;
                width: 30px;
                margin-right: 12px;
                svg {
                    margin: auto;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    @media (${breakpoints.small} <= width < ${breakpoints.medium}) {
        padding: 16px; 
        width: ${$cardWidth ? `${$cardWidth}` : ''};
        ${CardIconContainer} {
            height: 40px;
            width: 40px;
            svg {
                height: 20px;
                width: 20px;
            }
        }
        ${CardTitle} {
            font-size: 16px;
            font-weight: 800;
            line-height: 24px; /* 150% */
            margin: ${$iconColor && $iconPosition !== IconPosition.NEXT ? 16 : 0}px 0px 8px 0px;
            svg {
                margin-left: 8px;
                margin-top: -2px;
            }
            ${CardIconContainer} {
                height: 30px;
                width: 30px;
                margin-right: 12px;
                svg {
                    margin: auto;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
    @media (${breakpoints.medium} <= width < ${breakpoints.large}) {
        padding: 20px; 
        width: ${$cardWidth ? `${$cardWidth}` : ''};
        ${CardIconContainer} {
            height: 44px;
            width: 44px;
            svg {
                height: 22px;
                width: 22px;
            }
        }
        ${CardTitle} {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px; /* 140% */
            margin: ${$iconColor && $iconPosition !== IconPosition.NEXT ? 14 : 0}px 0px 12px 0px;
            svg {
                margin-top: -2px;
            }
            ${CardIconContainer} {
                height: 30px;
                width: 30px;
                margin-right: 12px;
                svg {
                    margin: auto;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
    @media (width >= ${breakpoints.large}) {
        padding: 24px; 
        width: ${$cardWidth ? `${$cardWidth}` : ''};
        ${CardIconContainer} {
            height: 48px;
            width: 48px;
            svg {
                height: 26px;
                width: 26px;
            }
        }
        ${CardTitle} {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px; /* 140% */
            
            margin: ${$iconColor && $iconPosition !== IconPosition.NEXT ? 16 : 0}px 12px 12px 0px;
            svg {
                margin-top: -2px;
            }
            ${CardIconContainer} {
                height: 34px;
                width: 34px;
                margin-right: 12px;
                svg {
                    margin: auto;
                    height: 16px;
                    width: 16px;
                }
            }

        }
    }
    height: ${$cardHeight};
    border-radius: 12px;
    border: 1px solid ${theme.colors.strokeSecondaryGrey10};
    box-shadow: ${theme.elevations.mid};
    
    ${CardIconContainer} {
        background: ${$iconColor ? iconColors.background[$iconColor] : ''};
        path {
            fill: ${$iconColor ? iconColors.default[$iconColor] : ''};
        }
    }

    &:hover, &:active, &:focus {
        ${CardDescription} {
            color: ${theme.colors.textPrimarySecondary1100};
        }
    }

    &:hover, &:focus {
        background: ${theme.colors.cardInteractionHoverGrey05};
    }
    
    &:hover {
        ${CardIconContainer} {
            background: ${$iconColor ? iconColors.hover[$iconColor] : ''}1A;
            path {
                fill: ${$iconColor ? iconColors.hover[$iconColor] : ''};
            }
        }
    }
    
    &:active {
        background: ${theme.colors.cardInteractionActiveSecondary420};
        box-shadow: ${theme.elevations.low};
        ${CardIconContainer} {
            background: ${$iconColor ? iconColors.active[$iconColor] : ''}1A;
            path {
                fill: ${$iconColor ? iconColors.active[$iconColor] : ''};
            }
        }
    }
    
    &:focus {
        outline: 3px solid ${theme.colors.chartChartLightbluePressedSecondary240};
    }
}
    `;
  }}
`;
