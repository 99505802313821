import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

type Props = IIconProps & {
  filled?: boolean;
};

export function AgreementsIcon({ color: colorProp, filled = true }: Props) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0C0.896875 0 0 0.896875 0 2V14C0 15.1031 0.896875 16 2 16H10C11.1031 16 12 15.1031 12 14V5H8C7.44688 5 7 4.55312 7 4V0H2ZM8 0V4H12L8 0ZM2.5 2H4.5C4.775 2 5 2.225 5 2.5C5 2.775 4.775 3 4.5 3H2.5C2.225 3 2 2.775 2 2.5C2 2.225 2.225 2 2.5 2ZM2.5 4H4.5C4.775 4 5 4.225 5 4.5C5 4.775 4.775 5 4.5 5H2.5C2.225 5 2 4.775 2 4.5C2 4.225 2.225 4 2.5 4ZM4.19375 11.9312C4.00312 12.5656 3.41875 13 2.75625 13H2.5C2.225 13 2 12.775 2 12.5C2 12.225 2.225 12 2.5 12H2.75625C2.97812 12 3.17188 11.8562 3.23438 11.6438L3.7 10.0969C3.80625 9.74375 4.13125 9.5 4.5 9.5C4.86875 9.5 5.19375 9.74063 5.3 10.0969L5.6625 11.3031C5.89375 11.1094 6.1875 11 6.5 11C6.99687 11 7.45 11.2812 7.67188 11.725L7.80937 12H9.5C9.775 12 10 12.225 10 12.5C10 12.775 9.775 13 9.5 13H7.5C7.30937 13 7.1375 12.8938 7.05312 12.725L6.77812 12.1719C6.725 12.0656 6.61875 12 6.50313 12C6.3875 12 6.27813 12.0656 6.22813 12.1719L5.95312 12.725C5.8625 12.9094 5.66563 13.0188 5.4625 13C5.25938 12.9812 5.08437 12.8406 5.02812 12.6469L4.5 10.9062L4.19375 11.9312Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9729 15H3.99458C3.44295 15 2.99729 14.5531 2.99729 14V2C2.99729 1.44687 3.44295 1 3.99458 1H7.98374V4.5C7.98374 5.32812 8.65379 6 9.47967 6H12.9702V14C12.9702 14.5531 12.5245 15 11.9729 15ZM9.47967 5C9.20542 5 8.98103 4.775 8.98103 4.5V1.01562C9.06829 1.0375 9.14932 1.08125 9.21165 1.14688L12.8237 4.76875C12.8892 4.83438 12.9328 4.9125 12.9546 5H9.47967ZM3.99458 0C2.89444 0 2 0.896875 2 2V14C2 15.1031 2.89444 16 3.99458 16H11.9729C13.073 16 13.9675 15.1031 13.9675 14V5.12187C13.9675 4.725 13.8085 4.34375 13.528 4.0625L9.91911 0.440625C9.63862 0.159375 9.2584 0 8.8626 0H3.99458ZM4.49322 2C4.21897 2 3.99458 2.225 3.99458 2.5C3.99458 2.775 4.21897 3 4.49322 3H6.4878C6.76206 3 6.98645 2.775 6.98645 2.5C6.98645 2.225 6.76206 2 6.4878 2H4.49322ZM4.49322 4C4.21897 4 3.99458 4.225 3.99458 4.5C3.99458 4.775 4.21897 5 4.49322 5H6.4878C6.76206 5 6.98645 4.775 6.98645 4.5C6.98645 4.225 6.76206 4 6.4878 4H4.49322ZM6.18238 11.9312L6.4878 10.9062L7.00827 12.6438C7.06125 12.825 7.21396 12.9594 7.39783 12.9937C7.58171 13.0281 7.77182 12.9531 7.88401 12.8L8.43252 12.0688C8.46369 12.025 8.51667 12 8.56965 12C8.63509 12 8.69431 12.0375 8.72236 12.0938L9.03713 12.7219C9.12127 12.8906 9.2958 12.9969 9.48279 12.9969H11.4774C11.7516 12.9969 11.976 12.7719 11.976 12.4969C11.976 12.2219 11.7516 11.9969 11.4774 11.9969H9.78821L9.61369 11.6438C9.41423 11.25 9.00908 11 8.56653 11C8.22371 11 7.89959 11.15 7.67832 11.4094L7.28564 10.0938C7.17968 9.74063 6.85556 9.5 6.4878 9.5C6.12005 9.5 5.79593 9.74063 5.68997 10.0969L5.22561 11.6438C5.16328 11.8562 4.96694 12 4.74878 12H4.49322C4.21897 12 3.99458 12.225 3.99458 12.5C3.99458 12.775 4.21897 13 4.49322 13H4.74878C5.40948 13 5.99228 12.5656 6.18238 11.9312Z"
        fill={color}
      />
    </svg>
  );
}

export default AgreementsIcon;
