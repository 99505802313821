import React from 'react';

import { Table } from 'shared/components/Table/Table';
import { TableCellString } from 'shared/components/Table/TableCell/TableCellString/TableCellString';
import { SentNotificationType } from 'modules/types/util';

type Props = {
  notificationsList: SentNotificationType[];
};

export function NotificationsTable({ notificationsList }: Props) {
  const columns = [
    { title: 'Date' },
    { title: 'Category' },
    { title: 'Title' },
    { title: 'Body' },
    { title: 'User Device' },
  ];

  const rows = notificationsList.map((notification) => {
    const date = new Date(notification.date_sent);

    return {
      key: `table-${notification.id}`,
      cells: [
        <TableCellString text={date.toLocaleString('en-US')} />,
        <TableCellString text={notification.category} />,
        <TableCellString text={notification.title} />,
        <TableCellString text={notification.body} />,
        <TableCellString text={notification.user_device} />,
      ],
    };
  });

  return <Table columns={columns} rows={rows} isFullWidth />;
}

export default NotificationsTable;
