import React, { useEffect } from 'react';
import { resolvePath } from 'react-router';

import Layout from 'shared/components/Layout/Layout';
import useUser from 'hooks/useUser';
import usePageName from 'hooks/usePageName';
import OverviewIcon from 'shared/components/icons/OverviewIcon';
import SystemIcon from 'shared/components/icons/SystemIcon';
import BillingIcon from 'shared/components/icons/BillingIcon';
import SupportIcon from 'shared/components/icons/SupportIcon';
import pages from 'pages';
import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { useBreakpoints } from 'hooks/useWindowSize';

export function DashboardLayout() {
  const { userResult, selectedAccount } = useUser();
  const { currentPage } = usePageName();
  const screenSize = useBreakpoints();

  const { hasDashboardAccess } = getDashboardRedirectPath(
    userResult?.user || undefined,
    selectedAccount,
  );

  const overviewIcon = () => <OverviewIcon filled={currentPage === 'OVERVIEW'} />;
  const systemIcon = () => <SystemIcon filled={currentPage === 'SYSTEM'} />;
  const billingIcon = () => <BillingIcon filled={currentPage === 'BILLING'} />;
  const supportIcon = () => <SupportIcon filled={currentPage === 'SUPPORT'} />;

  useEffect(() => {
    const { brandembassy } = window;
    const container = document.getElementById('be-frame');

    if (brandembassy && container) {
      if (screenSize === 'sm' || screenSize === 'md') {
        // for small screen sizes, manually adjust the chat button styling
        container.style.height = 'unset';
        brandembassy(
          'setCustomCss',
          `#be-messenger {
            bottom: 60px;
          } `,
        );
      } else {
        // return to defaults
        container.style.height = '74px';
        brandembassy(
          'setCustomCss',
          `#be-messenger {
            bottom: 0px;
          }`,
        );
      }
    }
  }, [screenSize]);

  const overviewMenuOptions = [
    {
      label: 'Overview',
      link: resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`),
      icon: overviewIcon,
    },
    ...(hasDashboardAccess
      ? [
          {
            label: 'System',
            link: resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`),
            icon: systemIcon,
          },
          {
            label: 'Billing',
            link: resolvePath(pages.BILLING, `/${pages.DASHBOARD}`),
            icon: billingIcon,
          },
        ]
      : []),
    {
      label: 'Support',
      link: !hasDashboardAccess
        ? resolvePath(`/${pages.SUPPORT}`)
        : resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`),
      icon: supportIcon,
    },
  ];

  return <Layout hasNav menuOptions={overviewMenuOptions} />;
}

export default DashboardLayout;
