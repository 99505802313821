import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import { Modal } from 'shared/components/Modal/Modal';
import { AddressType } from 'api/types';
import LocationIcon from 'shared/components/icons/LocationIcon';
import { CtaButtons } from 'shared/components/Modal/types';
import {
  BillingAddressModalContent,
  HeaderDiv,
  LocationDiv,
  StyledLocation,
  StyledLocationBottom,
  StyledText,
} from './BillingAddressModal.styles';

type BillingAddressType = Omit<AddressType, 'id' | 'archived' | 'confirmed'>;

export type BillingAddressModalProps = {
  isOpen: boolean;
  addressDisplay: BillingAddressType | undefined;
  onChangeConfirmed: () => void;
  onOpenBillingForm: () => void;
  onCloseBillingAddressModal: () => void;
  addressToChange: AddressType | undefined;
};

export function BillingAddressModal({
  isOpen,
  addressDisplay,
  onChangeConfirmed,
  onOpenBillingForm,
  onCloseBillingAddressModal,
  addressToChange,
}: BillingAddressModalProps) {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  let addressStreetOne = addressToChange?.street_1;
  let addressStreetTwo = addressToChange?.street_2;
  let addressCity = addressToChange?.city;
  let addressState = addressToChange?.state;
  let addressZip = addressToChange?.zip_code;
  if (addressDisplay !== undefined) {
    addressStreetOne = addressDisplay.street_1;
    addressStreetTwo = addressDisplay.street_2;
    addressCity = addressDisplay.city;
    addressState = addressDisplay.state;
    addressZip = addressDisplay.zip_code;
  }
  const addressLine1 = `${addressStreetOne} ${addressStreetTwo}`;
  const addressLine2 = `${addressCity}, ${addressState} ${addressZip}`;
  const ctaButtons = () => {
    const buttons: CtaButtons = {
      tertiary: {
        label: t('cta.update'),
        onClick: onOpenBillingForm,
      },
      primary: {
        label: t('cta.confirm'),
        onClick: onChangeConfirmed,
      },
    };
    return buttons;
  };
  return (
    <Modal
      contentLabel={t('autopayModal.setUpAutopay')}
      title={t('confirmBillingAddress.modalTitle')}
      styleVariant="tertiary"
      isOpen={isOpen}
      isFullWidth={screenSize === 'sm'}
      fitContent
      padding="32px"
      ctaButtons={ctaButtons()}
      onRequestClose={onCloseBillingAddressModal}
    >
      <BillingAddressModalContent>
        <HeaderDiv>
          <Trans>
            <StyledText>
              {addressDisplay !== undefined
                ? t('confirmBillingAddress.billingQuestion', { bills: 'first bill' })
                : t('confirmBillingAddress.billingQuestion', { bills: 'bills' })}
            </StyledText>
          </Trans>
        </HeaderDiv>
        <LocationDiv>
          <LocationIcon />
          <StyledLocation>{addressLine1}</StyledLocation>
        </LocationDiv>
        <StyledLocationBottom>{addressLine2}</StyledLocationBottom>
      </BillingAddressModalContent>
    </Modal>
  );
}

export default BillingAddressModal;
