export const INTERNAL_USER_ROLES = {
  ADMIN: 'ADMIN',
  SUPPORT: 'SUPPORT',
  MARKETING: 'MARKETING',
  SUPER_ADMIN: 'SUPER_ADMIN',
} as const;

export const USER_ROLES = {
  ...INTERNAL_USER_ROLES,
  HOMEOWNER: 'HOMEOWNER',
  IMPERSONATE_ONLY: 'IMPERSONATE_ONLY',
} as const;

export type RolesKeyInternal = keyof typeof INTERNAL_USER_ROLES;
export type RolesKey = keyof typeof USER_ROLES;

export const languageCode = {
  en: 'ENGLISH',
  es: 'SPANISH',
} as const;

export type LanguageCodeKey = keyof typeof languageCode;
export type PrimaryLanguage = (typeof languageCode)[LanguageCodeKey];

export enum MilestoneType {
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  PROJECT_QUALIFICATION_COMPLETED = 'PROJECT_QUALIFICATION_COMPLETED',
  INSTALLATION_COMPLETED = 'INSTALLATION_COMPLETED',
  IN_SERVICE = 'IN_SERVICE',
}

export enum WelcomeChecklistStatusType {
  REQUIRED = 'REQUIRED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum WelcomeChecklistResult {
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export interface WelcomeChecklistType {
  id: string;
  user?: string;
  agreement: string;
  checklist: WelcomeChecklistItemType[];
  date_completed: string | null;
  result: WelcomeChecklistResult | null;
}

export interface WelcomeChecklistItemType {
  page: number;
  question_en: string;
  question_es: string;
  answer?: string;
}

export interface SentEmailType {
  id: string;
  email_template: string;
  template_description: string;
  date_sent: string;
  user_email: string;
}

export interface SentNotificationType {
  id: string;
  user_device: string;
  agreement: string;
  name: string;
  category: string;
  title: string;
  body: string;
  date_sent: string;
}

export type ChartDataType = {
  x: number;
  y: number;
  unit: string;
  value: number;
  time: Date;
};

export type AxisType = {
  domain?: [number, number];
  tickValues?: unknown[];
  tickFormat?: (tick: any, index: number, ticks: any) => string | number;
  style?: any;
};

export type Tuple<T> = [T, T];

export declare type PaddingType = number | Tuple<number>;

export enum TabType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
