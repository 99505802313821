declare global {
  // This interface is, in fact, used (see inContact.onload below)
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    BrandEmbassy: any;
    brandembassy: {
      (command: string, ...args: any[]): void;
      q?: any[];
    };
  }
}

const brandEmbassyIframe = 'iframe[id^="BrandEmbassy-iframe"]';

export const showSupportChat = () => {
  const iframe = document.querySelector(brandEmbassyIframe) as HTMLIFrameElement;

  if (iframe) {
    iframe.style.display = 'block';
  } else {
    setTimeout(showSupportChat, 50);
  }
};

export const hideSupportChat = () => {
  const iframe = document.querySelector(brandEmbassyIframe) as HTMLIFrameElement;
  if (iframe) {
    iframe.style.display = 'none';
  } else {
    setTimeout(hideSupportChat, 50);
  }
};
