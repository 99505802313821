import { RolesKey, USER_ROLES } from 'modules/types/util';

export function isHomeowner(role?: RolesKey): boolean {
  return role === USER_ROLES.HOMEOWNER;
}

export function isSupport(role?: RolesKey): boolean {
  return (
    role === USER_ROLES.SUPPORT || role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN
  );
}

export function isAdmin(role?: RolesKey): boolean {
  return role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN;
}

export function isSuperAdmin(role?: RolesKey): boolean {
  return role === USER_ROLES.SUPER_ADMIN;
}

export function isMarketing(role?: RolesKey): boolean {
  return role === USER_ROLES.MARKETING || role === USER_ROLES.ADMIN;
}

export function isImpersonateOnly(role?: RolesKey): boolean {
  return role === USER_ROLES.IMPERSONATE_ONLY;
}
