import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  SystemCardTotalOutputValueWrapper,
  SystemCardTotalOutputValue,
  SystemCardTotalOutputValueSuffix,
} from './SystemCardOutputsSection.styles';

export default function SystemCardTotalOutputText({ value }: { value: string }) {
  const { t } = useTranslation();
  return (
    <SystemCardTotalOutputValueWrapper>
      <SystemCardTotalOutputValue as="p">{value}</SystemCardTotalOutputValue>

      <SystemCardTotalOutputValueSuffix as="p">
        {t('systemCard.totalOutputSuffix')}
      </SystemCardTotalOutputValueSuffix>
    </SystemCardTotalOutputValueWrapper>
  );
}
