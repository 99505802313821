/* eslint-disable no-sequences */
/* eslint-disable prefer-template */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-undef */

(function (n, u) {
  (window.BrandEmbassy = n),
    (window[n] =
      window[n] ||
      function () {
        (window[n].q = window[n].q || []).push(arguments);
      }),
    (window[n].u = u);

  const e = document.createElement('script');
  e.async = 1;
  e.src = u + '?' + Math.round(Date.now() / 1e3 / 3600);
  document.head.appendChild(e);
})('brandembassy', 'https://livechat-static-de-na1.niceincontact.com/4/chat.js');

brandembassy('init', 3536, 'chat_98192d76-e01b-4e18-8145-02aa6bac379b');

// When the user receives a message, remove the layover
brandembassy('onPushUpdate', ['MessageCreated'], (e) => {
  if (e.data.message.isRead) {
    const iframe = document.querySelector('iframe[id^="BrandEmbassy-iframe"]');
    if (iframe)
      iframe.contentWindow.document.querySelector('*[class^="WaitingQueueModal"]').style.display =
        'none';
  }
});
