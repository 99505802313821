import Text from 'shared/components/Text/Text';
import styled, { css } from 'styled-components';

export const SystemCardTotalOutputValue = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textPrimarySecondary1100};
        font-size: 24px;
        line-height: 1.5;
        font-weight: 500;
        font-style: normal;
    `}
`;

export const SystemCardTotalOutputValueSuffix = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textPrimarySecondary1100};
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        font-style: normal;
        padding: 0px 0px 5px 8px;
    `}
`;

export const SystemCardTotalOutputValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 4px 0 24px 44px;
`;

export const SystemCardOutputsWrapper = styled.div<{ $hasBattery: boolean }>`
  ${({ theme, $hasBattery }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-evenly;
    align-items: center;
    align-self: stretch;
    border-radius: 20px;
    background: ${theme.colors.backgroundSecondaryGrey05};
    box-shadow:
      0px 0.5px 1px 0px rgba(34, 52, 91, 0.03) inset,
      0px 0.5px 2px 0px rgba(34, 52, 91, 0.11) inset;
    padding: 16px;

    ${$hasBattery
      ? css`
          > div {
            &:nth-last-child(1):nth-child(odd) {
              grid-column: span 2;
            }
          }
        `
      : ''}

    @media screen and (min-width: ${theme.breakpoints.small}) {
      ${$hasBattery
        ? css`
            grid-template-columns: repeat(3, 1fr);
            > div {
              &:nth-last-child(1):nth-child(odd) {
                grid-column: auto;
              }
            }
          `
        : ''}
    }
  `}
`;
