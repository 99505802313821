import styled from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const UserProfileContent = styled.div`
  display: flex;
  gap: 48px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 60px;
  max-width: 254px;
`;

export const SectionTitle = styled(Text)`
  font-family: Avenir;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
`;

export const EmailHeader = styled.div`
  display: flex;
  gap: 16px;
`;

export const TransferCards = styled.div`
  display: flex;
  gap: 16px;
`;

export const CommunicationsTableWrapper = styled.div`
  max-height: 464px;
  overflow: scroll;
`;
