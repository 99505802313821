import React from 'react';
import { useTheme } from 'styled-components';

import houseLight from 'static/house.light-mode.svg';
import houseDark from 'static/house.dark-mode.svg';
import houseNoBatteryLight from 'static/house-no-battery.light-mode.svg';
import houseNoBatteryDark from 'static/house-no-battery.dark-mode.svg';
import { HouseGraphicImage } from './HouseGraphic.styles';

type Props = {
  hasBattery: boolean;
};
export function HouseGraphic({ hasBattery }: Props) {
  const theme = useTheme();
  let asset;

  if (theme.name === 'darkTheme') {
    asset = hasBattery ? houseDark : houseNoBatteryDark;
  } else {
    asset = hasBattery ? houseLight : houseNoBatteryLight;
  }
  return <HouseGraphicImage src={asset} />;
}

export default HouseGraphic;
