import React from 'react';
import {
  SystemCardIndividualOutputWrapper,
  SystemCardIndividualOutputHeader,
  SystemCardIndividualOutputValueWrapper,
  SystemCardIndividualOutputValue,
  SystemCardIndividualOutputValueSuffix,
} from './SystemCardIndividualOutput.styles';

type SystemCardIndividualOutputProps = {
  header: string;
  value: string;
  borderLeft?: boolean;
  mobileBorderTop?: boolean;
  marginBottom?: boolean;
};

export default function SystemCardIndividualOutput({
  header,
  value,
  borderLeft = false,
  mobileBorderTop = false,
  marginBottom = false,
}: SystemCardIndividualOutputProps) {
  return (
    <SystemCardIndividualOutputWrapper
      data-is-individual-output
      $borderLeft={borderLeft}
      $addMarginBottom={marginBottom}
      $mobileBorderTop={mobileBorderTop}
    >
      <SystemCardIndividualOutputHeader as="p">{header}</SystemCardIndividualOutputHeader>
      <SystemCardIndividualOutputValueWrapper>
        <SystemCardIndividualOutputValue as="p">{value}</SystemCardIndividualOutputValue>
        <SystemCardIndividualOutputValueSuffix as="p">kWh</SystemCardIndividualOutputValueSuffix>
      </SystemCardIndividualOutputValueWrapper>
    </SystemCardIndividualOutputWrapper>
  );
}
