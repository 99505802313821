import Text from 'shared/components/Text/Text';
import styled, { css } from 'styled-components';

export const SystemCardIndividualOutputWrapper = styled.div<{
  $borderLeft: boolean;
  $mobileBorderTop: boolean;
  $addMarginBottom: boolean;
}>`
  ${({ theme, $borderLeft, $mobileBorderTop, $addMarginBottom }) => {
    const borderTop = $mobileBorderTop
      ? `1px solid ${theme.colors.backgroundInteractionActiveGrey20}`
      : 'none';
    const borderLeft =
      $borderLeft && !$mobileBorderTop
        ? `1px solid ${theme.colors.backgroundInteractionActiveGrey20}`
        : 'none';
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      min-width: 65px;
      max-width: 100%;
      flex: 1 1 auto;
      border-top: ${borderTop};
      border-left: ${borderLeft};
      padding-top: ${$mobileBorderTop ? '16px' : '0'};
      margin-bottom: ${!$mobileBorderTop && $addMarginBottom ? '16px' : '0'};
      @media screen and (min-width: ${theme.breakpoints.small}) {
        border-top: none;
        border-left: ${$mobileBorderTop
          ? `1px solid ${theme.colors.backgroundInteractionActiveGrey20}`
          : borderLeft};
        padding-top: 0;
        margin-bottom: 0;
      }
    `;
  }}
`;

export const SystemCardIndividualOutputHeader = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textSecondaryGrey90};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    `}
`;

export const SystemCardIndividualOutputValue = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textPrimarySecondary1100};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    `}
`;

export const SystemCardIndividualOutputValueSuffix = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textPrimarySecondary1100};
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        padding: 0px 0px 1px 2px;
    `}
`;

export const SystemCardIndividualOutputValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
